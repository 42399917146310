import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Landing from "../components/index/landing"
import Button from "../components/button"
import { graphql } from "gatsby"
import indexStyles from "./index.module.scss"
import styles from "./icehockey.module.scss"
import videoPoster from "../images/soccer/video-poster.webp"
import soccerVid from "../videos/sports/Soccer.mp4"
import CallToActionBlock from "../components/iceHockey/callToActionBlock"
import Feature from "../components/iceHockey/feature"
import VideoSection from "../components/iceHockey/videoSection"
import { openModal } from "../services/modal"
import Footer from "../components/footer";

class Soccer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { pageContext } = this.props
    const {
      texts: { _ },
      headerImgs,
    } = this.props.data

    const featureImgs = this.props.data.featureImgs.edges.reduce(
      (res, curr) => ({
        ...res,
        [curr.node.name]: curr.node?.childImageSharp?.fluid,
      }),
      {}
    )

    const languageCode =
      pageContext && pageContext.lang ? pageContext.lang : "en"
    const headerImage = headerImgs.edges.find((i) => i.node.name === "soccer")

    return (
      <Layout
        header={
          <Header
            num={1}
            translations={_}
            headerFullYOffset={5000}
            mobileHeaderFullYOffset={40}
            languageCode={languageCode}
            hideNav
            staticHeader
          />
        }
        translations={_}
        languageCode={languageCode}
        // We need new gtagIdentificator for soccer page
        // gtagIdentificator="G-SZS1D5TJCF"
      >
        <Landing
          hideLogo
          heading={<>Take your football <br /> to another level</>}
          subheading="Complete software solution for modern clubs and coaches"
          descriptionItems={[
            "Whole organisation managed in one place",
            "Planning, Monitoring, Analysis, Communication",
            "New XPS Health medical module",
          ]}
          randomImg={headerImage}
          languageCode={languageCode}
          styles={indexStyles}
          actionButton={
            <div className={styles.landingButtons}>
              <Button onClick={openModal}>BOOK A MEETING</Button>
              <Button onClick={openModal} light>
                START FREE TRIAL
              </Button>
            </div>
          }
        />
        <Feature
          title="Trusted by the pros."
          description="From managing single athletes to national teams, trainers and coaches use XPS Network to improve their performance."
          image={featureImgs.trusted}
        />
        <VideoSection
          title={_.sports_page.video_title}
          src={soccerVid}
          poster={videoPoster}
        />
        <CallToActionBlock title="It's time to get started" />
        <Feature
          name="Monitoring"
          title="Make data-driven coaching decisions"
          description="monitor readiness, wellness and training load"
          image={featureImgs.monitoring}
        />
        <Feature
          name="Collections"
          title="Create your lifetime of coaching observation"
          description="build a library of drills, exercises, documents or player information"
          image={featureImgs.collections}
          leftCut
        />
        <Feature
          name="Analysis"
          title="Analyze your performance"
          description="and generate high levels of accountability and feedback"
          image={featureImgs.analysis}
          rightCut
        />
        <CallToActionBlock
          title="The Soccer Chance Accademy"
          subtitle="Soccer Revolution with Complex use of all XPS Features"
          videoId="rHIwKHI4Ebs"
        />
        <Feature
          name="Injury Management"
          title="Report injuries and illnesses"
          description="provide seamless communication between your medical team and coaching staff"
          image={featureImgs["injury-management"]}
        />
        <Feature
          name="Tactics"
          title="Recreate training and game situations"
          description="through diagrams and animations in XPS Playbook"
          image={featureImgs.tactics}
          rightCut
        />
        <Feature
          name="Planning"
          title="Build and plan team and individual sessions"
          description="with Calendar, Session Planner and Collections features"
          image={featureImgs.planning}
          leftCut
        />
        <Feature
          name="Communication"
          title="Stay in touch with coaches and athletes"
          description="share videos, animations, photos or documents, all in one place"
          image={featureImgs.communication}
          rightCut
        />
        <CallToActionBlock
          title="Virgina Women's Soccer"
          subtitle="Individual Player Feedback with XPS Network"
          videoId="-BjJWBx09hA"
          wrapperStyles={{ marginBottom: 0 }}
        />
        <Footer translations={_} languageCode={languageCode} />
      </Layout>
    )
  }
}

export default Soccer

export const query = graphql`
  query soccer($langFile: String) {
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        ...TryXpsModalTranslations
        time_to_get_started
        start_free_trial
        ready_to_start
        enter_your_email
        sport_detail {
          analyze {
            subheader
            header
            paragraph
          }
          teams {
            header
          }
        }
        sports_page {
          heading
          subheading
          download_pdf
          video_title
          stories_title
          complete_solution
          sections {
            section_header
            texts {
              header
              paragraph
            }
          }
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
    headerImgs: allFile(filter: { relativePath: { glob: "sports/header/*" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1093) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    featureImgs: allFile(
      filter: { relativePath: { glob: "soccer/features/*" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
