import soccerPage from "./soccer"

export default soccerPage

export const query = graphql`
  query football($langFile: String) {
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        ...TryXpsModalTranslations
        time_to_get_started
        start_free_trial
        ready_to_start
        enter_your_email
        sport_detail {
          analyze {
            subheader
            header
            paragraph
          }
          teams {
            header
          }
        }
        sports_page {
          heading
          subheading
          download_pdf
          video_title
          stories_title
          complete_solution
          sections {
            section_header
            texts {
              header
              paragraph
            }
          }
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
    headerImgs: allFile(filter: { relativePath: { glob: "sports/header/*" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1093) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    featureImgs: allFile(
      filter: { relativePath: { glob: "soccer/features/*" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
